@tailwind base;
@tailwind components;
@tailwind utilities;

.cursor {
  background-color: #9999df;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  position: fixed;
  top: 0;
  left: 0;
}

/* .cursor-dot {
  width: 5px;
  height: 5px;
  background-color: white;
}

.cursor-outline {
  width: 30px;
  height: 30px;
  border: 2px solid white;
  border-radius: 100%;
}

.cursor-dot,
.cursor-outline {
  position: fixed;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  z-index: 1;
  pointer-events: none;
} */
